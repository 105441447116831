$numColumns: 12;
$gutterWidth: 15px;
$textPrimary: rgba(0, 0, 0, 0.7);
$textSecondary: rgba(0, 0, 0, 0.5);
$textOnBg: #fff;
$primary: #0052cc;
$secondary: #bcbcbccc;
$danger: #ff0000;
$success: #008000;
$bgPrimary: #ffffff;
$bgSecondary: #f6f7ff;
$inputHover: #4a4a4a;
$textDisabled: rgba(0, 0, 0, 0.5);
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
@mixin break-point-min-max($startBreakpoint, $endBreakpoint) {
  @media (min-width: map-get($breakpoints, $startBreakpoint)) and (max-width: map-get($breakpoints, $endBreakpoint)) {
    @content;
  }
}
.h6,
.h5,
.h4,
.h3,
.h2,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1 {
    font-size: 2.5rem;
  }
}

.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2 {
    font-size: 2rem;
  }
}

.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3 {
    font-size: 1.75rem;
  }
}

.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4 {
    font-size: 1.5rem;
  }
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}
.text-primary-bg {
  color: $primary;
}

.text-primary {
  color: $textPrimary !important;
}

.text-secondary {
  color: $textSecondary;
}

.text-disabled {
  color: $textDisabled;
}

.text-onBg {
  color: #fff;
}

.capitalize {
  text-transform: capitalize;
}

.row {
  display: grid;
  grid-template-columns: repeat(
    $numColumns,
    5fr
  ); // Uniform columns. No need for rounded percentages to calculate widths.
  grid-gap: $gutterWidth; // Actual gutter width. No need for negative margin hack.
}

[class^="col-"] {
  grid-column-end: span $numColumns; //Full width fallback when no column size is define for the screen size.
}

@for $i from 1 through $numColumns {
  .col-#{$i} {
    grid-column-end: span $i;
  }
}

@each $size, $abbr in (0, xs), (576px, sm), (768px, md), (992px, lg),
  (1200px, xl), (1400px, xxl)
{
  @media (min-width: $size) {
    // Loop through col classes
    @for $i from 1 through $numColumns {
      .col-#{$abbr}-#{$i} {
        grid-column-end: span $i;
      }
    }
  }
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.overflow-y {
  overflow-y: auto;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.rounded {
  border-radius: 100%;
}

.bg-transparent {
  background: transparent !important;
}

a {
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.d-flex-important {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-revert {
  justify-content: revert;
}

.justify-end {
  justify-content: right;
}

.justify-start {
  justify-content: left;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-auto {
  width: auto !important;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.h-auto {
  min-height: fit-content !important;
  height: auto !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

/* Classes de borda (Border Classes) */
.border {
  border: 1px solid #00000026 !important;
  /* Exemplo de uma borda sólida de 1px na cor preta */
}

.border-top {
  border-top: 1px solid $textPrimary;
}

.border-right {
  border-right: 1px solid $textPrimary;
}

.border-bottom {
  border-bottom: 1px solid $textPrimary;
}

.border-left {
  border-left: 1px solid $textPrimary;
}

/* Classes de cantos arredondados (Rounded Classes) */
.rounded {
  border-radius: 0.25rem;
  /* Exemplo de um raio de 0.25rem (4 pixels) para todos os cantos */
}

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.bg-primary {
  background: $bgPrimary;
}

.bg-secondary {
  background: $bgSecondary;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.menus {
  display: none;
  align-items: center;
  flex-wrap: nowrap;
  list-style: none;
  min-width: max-content;
}

@media (min-width: 576px) {
  .menus {
    display: flex;
  }
}

.menu-items {
  position: relative;
  font-size: 14px;
  min-width: max-content;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background: #e8e8e8;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: auto;
  left: auto;
  box-shadow:
    0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 100;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.position-right {
  right: 0 !important;
}

.position-left {
  left: 0 !important;
}

.position-auto {
  left: auto;
  right: auto;
}

.mobile-menu {
  display: none;

  @media (max-width: 575.98px) {
    display: block;
  }
}

.accordion-label {
  font-size: 1.5rem;
}

.bg-hover {
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

.bg-hover-secondary {
  &:hover {
    background: #f6f7ff;
  }
}

.text-hover {
  &:hover {
    color: black;
  }
}

.border-0 {
  border: none;
}

.text-left {
  text-align: left;
}

.logo-desktop {
  object-fit: contain;
  display: block;

  @media (max-width: 575.98px) {
    display: none;
  }
}

.logo-mobile {
  object-fit: contain;
  display: none;

  @media (max-width: 575.98px) {
    display: block;
    height: 2.5rem;
  }
}

.border-left-dashed {
  border-left: 0.18rem dashed rgba(0, 0, 0, 0.43);
}

.gap-1 {
  gap: 1rem;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.btn {
  padding: 0.7rem calc(0.7rem * 2.5);
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  color: #ffffff;
}

.bg-success {
  background-color: $success;
}

.bg-danger {
  background-color: $danger;
}

.no-scroll {
  overflow: hidden;
}

.is-invalid {
  border-bottom: 1px solid $danger !important;
}

.is-valid {
  border-bottom: 1px solid $success !important;
}

.modal-popup {
  padding: 0 0 1rem 0 !important;
  @include break-point-min-max(sx, sm) {
    width: 100vw;
    height: 100vh;
    display: flex !important;
    flex-direction: column !important;
    justify-content: start;
  }
}

.modal-close {
  @include break-point-min-max(sx, sm) {
    margin-left: auto;
  }
}

.modal-title {
  padding: 2rem 1em 0;
  text-align: start;
}

.modal-content {
  padding: 1em 1em 0.3em !important;
}

.modal-actions {
  display: flex;
  flex-direction: column;

  button {
    margin: 1rem auto !important;
  }

  @include break-point-min-max(sx, sm) {
    margin: auto;
    flex-direction: row;
    width: 100%;
    button {
      padding: 1rem 1.6rem !important;
      font-size: 1.3rem !important;
      margin: 0 0.6rem;
    }
  }
}
